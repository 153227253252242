/* @media (min-width: 770px) {} */
.App {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Exo 2', Roboto, sans-serif;
  font-size: 10px;
  color: #ffffff;
  height: 100vh;
}
@media (max-width: 769px) {
  .App {
    overflow-y: scroll;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #000000A0;
  width:100%;
  min-height: 100vh;
  overflow-x: hidden;
  backdrop-filter: blur(6px);
}

.view-space {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 96vw;
  max-width: 1280px;
  height: 100%;
}

.banner {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.banner-logo-container {
  height: 80%;
  padding-right: 50px;
  border-right: solid 1px #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 769px) {
.banner-logo-container {
  padding-right: 20px;
}
}

.banner-logo {
  width: 125px;
}
@media (max-width: 769px) {
  .banner-logo {
    width: 100px;
  }
}

.text-container {
  height: 80%;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 769px) {
  .text-container {
  padding-left: 20px;
}
}

.text-container .title {
  font-size: 4.5rem;
  margin-bottom: 10px;
}
.text-container .subtitle {
  font-size: 1.5rem;
}
@media (max-width: 769px) {
  .text-container .title {
    font-size: 3rem;
    margin-bottom: 5px;
  }
  .text-container .subtitle {
    font-size: 1rem;
  }
}

.cards-container {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.section-header {
  font-size: 1.2rem;
  margin: 50px 0px 20px 10px;
  align-self: flex-start;
}
.section-cards-container {
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 769px) {
  .cards-container {
    height: auto;
    flex-direction: column
  }
  .section-header {
    font-size: 1rem;
    margin: 20px 0px 10px 30px;
    align-self: flex-start;
  }
  .section-cards-container {
    height: auto;
    flex-direction: column
  }
}


.appcard-card {
  color: #000000C0;
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0px 0px 2px 0px #aaa;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
  cursor: pointer;
  margin:10px;
  font-size: 1.2rem;
  transition: box-shadow 0.1s ease-out, font-size 0.1s ease-out;
}

.appcard-card:hover {
  box-shadow: 0px 0px 8px 1px #ccc;
  font-size: 1.5rem;
}

.appcard-card-disabled {
  color: #00000050;
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0px 0px 2px 0px #aaa;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
  margin:10px;
  font-size: 1.2rem;
}

@media (max-width: 769px) {
  .appcard-card {
    width: 80%;
  }
  .appcard-card-disabled {
    width: 80%;
  }
  .appcard-card:hover {
    font-size: 1.2rem;
  }
}

.card-title {
  margin: 0;
  text-align: center;
}

.coming-soon {
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #000000;
  font-size: 1rem;
}
.login-title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 23px;
}
.login-card {
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 320px;
  margin-bottom: 125px;
}

h2 {
  margin-top: 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

input[type='text'],
input[type='password'] {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type='submit'] {
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 16px;
  background-color: #0077c2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #005b8a;
}

.forgot-password {
  margin-top: 16px;
  text-align: center;
}

.error {
  font-size: small;
  color: red;
}

.link {
  color: #0077c2;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.login-logo-container {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.login-logo {
  width: 5rem;
  padding-bottom: 10px;
}

@media (min-width: 770px) {
  .footer {
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background:#000000A0;
    font-size:small;
    text-align: center;
    color: #fff;
    padding-right: 10px;
    padding-left: 10px;
  }

  .socials {
    height:40px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
  .socials img{
    width: 24px;
    height: 24px;
    margin: 10px;
    cursor: pointer;
    transition: width 0.1s ease-out, height 0.1s ease-out;
  }
  .socials div{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .socials img:hover{
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 769px) {
  .footer {
    font-size:small;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #fff;
    width: 100%;
    margin-top: 20px;
  }
  .socials {
    height:40px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
  .socials img{
    width: 24px;
    height: 24px;
    margin: 10px;
    cursor: pointer;
    transition: width 0.1s ease-out, height 0.1s ease-out;
  }
  .socials div{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.footer a:link {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
}

.footer a:visited {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
}

.footer a:hover {
  color: #fff;
  background-color: transparent;
  text-decoration: underline;
}

.footer a:active {
  color: #fff;
  background-color: transparent;
  text-decoration: underline;
}